"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getQueryKey = exports.QUERY_KEYS = void 0;
const helpers_1 = require("./helpers");
exports.QUERY_KEYS = {
    REACTIONS: "reactions:{id}:{type}",
    ROLE_BY_PERMISSIONS: "rolePermission:{roleId}",
    CHAT_MESSAGES: "chat:{conversationId}",
    CHAT_MESSAGES_SEARCH: "chat:{serverId}:messages:search",
    CHAT_MESSAGE: "chat:{id}:message",
    THREADS: "threads:{serverId}:{messageId}",
    THREAD_INFO: "thread:{messageId}:info",
    COMMENT_COMMENT: "comment:{parentId}:comments",
    THREAD_COMMENT: "thread:{threadId}:comments",
    CHANNEL: "channels:{serverId}:{channelId}",
    CHANNELS: "channels:{serverId}",
    CHANNELS_DAFAULT: "channels:{serverId}:dafault",
    PERMISSIONS_LIST: "permission-list:{serverId}",
    PERSONAL_CONVERSATIONS: "personal:conversations",
    EVENT_CHATS: "event:chats:conversations",
    MY_CONVERSATION: "my:conversation",
    CONVERSATION: "conversation:{conversationId}",
    MEMBER_INFO: "{serverId}:member:{memberId}",
    MEMBER_RESPONSES: "{serverId}:member:responses:{memberId}",
    CATEGORIES: "{serverId}:categories",
    COUNTRIES: "countries",
    GLOBAL_MEMBERS: "global:{conversationId}:members",
    CONVERSATION_MEMBERS: "conversation:{conversationId}:members",
    SERVER_MEMBERS: "{serverId}:members",
    GLOBAL_USERS: "global:users",
    SERVER_MEMBER_LIST: "{serverId}:members:list",
    SERVER_INVITIES_MEMBER_LIST: "{serverId}:members:invities:list",
    SERVER_ROLE_MEMBER_LIST: "{serverId}:members:list",
    SERVER_ROLES_LIST: "{serverId}:roles:list",
    SERVER_MEMBER_PAGINATED: "conversation:members:list:{conversationId}",
    PINNED: "conversation:{conversationId}:pinned",
    INVITE_CODE: "{serverId}:invite-code",
    ONBOARDING_SETTING: "{serverId}:onboarding-settings",
    ONBOARDING_QUESTIONS: "{serverId}:onboarding-questions",
    MY_SERVERS: "my-servers",
    SERVER_INFO: "server:{serverId}",
    SERVER_SETTINGS: "server:{serverId}:settings",
    STEPS: "server:steps:{serverId}",
    GUIDELINES: "server:guidelines:{serverId}",
    USER_INFO: "user:{userId}",
    UNREADS: "unreads:{userId}",
    COMMUNITIES: "communities",
    EVENTS: "events",
    BROWSE_CHANNELS: "channels:browse:{serverId}",
    CHANNEL_ATTACHMENTS: "channels:attachments:{channelId}",
    PERMISSIONS: "permissions",
    EVENT_LIST: "events",
    BOT_CONVERSATIONS: "bot:conversations:{serverId}",
    SEARCH_USER: "search:user:{serverId}",
    SEARCH_MESSAGE: "search:message:{serverId}",
    ROLES: "search:message:{serverId}",
    SEARCH_EVENTS: "search:events",
    GUESTLIST_GROUP: "event:group",
    PUBLIC_CALENDAR: "public:calendar",
    PRIVATE_CALENDAR: "private:calendar",
    COMMUNITY_CALENDAR: "commmunity:calendar",
    CALENDAR_INFO: "calendar:info",
    CALENDAR_EVENTS: "calendar:calendarId",
    CALENDAR_EVENTS_ADMIN: "calendarId:startEpoc",
    CALENDAR_EVENTS_BY_ALIAS: "calendar:alias:events",
    CALENDAR_POPULAR_EVENTS: "calendar:popular-events",
    CALENDAR_EVENTS_LOCATION: "calendar:event-location",
    SEARCH_FEDERATED_CALENDARS: "search:federated:calendar",
    SERVER_SECTIONS: "server:sections:{serverId}",
    SERVER_HOME_SECTION: "server:home:section:{serverId}",
    SERVER_BASIC_SECTION: "server:basic:section:{serverId}",
    SERVER_SOCIAL_SECTION: "server:social:section:{serverId}",
    AVATARS: "server:avatars",
    NOTIFICATIONS: "notifications",
    STRIPE_CONNECTED_ACC: "connected-account",
    EVENT_CONVERSATIONS: "evnet:conversations:{eventId}",
    EVENT_TEAM: "evnet:team:{eventId}",
    CALENDAR_ACTIVITIES: "calender-activities",
    EVENT_FEATURED_GUEST: "event-featured-guest",
    EVENT_HOST: "event-host",
    EVENT_GUEST: "event-guest:{eventId}",
    EVENT_PROXY_GUEST: "event-guest-proxy:{eventId}",
    PARTICIPANTS_STATS: "participants-stats",
    MEMBER_PERMISSIONS: "member-permissions",
    GEO_LOCATION: "calender-activities",
    EVENT_EXPLORE_LIST: "event-list",
    NEARBY_EVENT_LIST: "nearby-event-list",
    DISCOVER_CITIES: "discover-cities",
    EVENT_INFO: "event:{alias}",
    CALENDAR_TAGS: "calendar-tag",
    CALENDAR_SUBSCRIBERS: "calendar-subscribers",
    TIMEZONES: "timezones",
    FAVOURITES_CONVERSATIONS: "favourites:conversations",
    FAVOURITES_CONVERSATIONS_CHANNELS: "favourites:conversations:channels",
    GOOGLE_CONTACTS: "google-contacts:userId",
    USER_KEYS: "USER_KEYS",
    CONVERSATION_KEYS: "CONVERSATION_KEYS:{conversationId}",
    CONVERSATION_PARTICIPANTS_KEYS: "CONVERSATION_PARTICIPANTS_KEYS",
    UNSPLASH: "unsplash",
    APPLICATION_INFO: "APPLICATION_INFO",
    ATTENDYEE_STATUS: "atendeeDtatus{eventId:eventId}",
    EMAIL_ANALYTCIS: "emailanalytcis{eventId:eventId}",
    USER_REGISTRATION_ANALYTICS: "useranalytcis{eventId:eventId}",
    REGISTRATION_TICKETS: "ticketsanalytcis{eventId:eventId}",
    EVENT_REVENUE_ANALYTICS: "eventrevenue{eventId:eventId}",
    EVENT_GEOGRAPHIC_DIAT_DATA: "eventgrodata{eventId:eventId}",
    REGISTRATION_GRAPH: "registrationgraph{eventId:eventId}",
    EVENT_CHECKIN_ANALYTICS: "eventcheckin{eventId:eventId}",
    POST_EVENT_ATTENDENCE: "posteventanalytics{eventId:eventId}",
    EVENT_FEEDBACK_SUMMARY: "eventfeedback{eventId:eventId}",
    EVENT_CHAT_ANALYTICS: "eventchatanalytics{eventId:eventId}",
    ACCESSTYPE_GRAPH: "accesstype{eventId:eventId}"
};
const getQueryKey = (key, params) => {
    const template = exports.QUERY_KEYS[key];
    return (0, helpers_1.replaceDynamicParams)(template, params);
};
exports.getQueryKey = getQueryKey;
