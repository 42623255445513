"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSocketKey = exports.SOCKET_KEYS = void 0;
const helpers_1 = require("./helpers");
exports.SOCKET_KEYS = {
    CHAT_MESSAGES: "chat:{conversationId}:messages",
    REACTIONS: "reactions",
    REACTIONS_MESSAGES: "reactions:{conversationId}",
    THREADS: "thread:{conversationId}:messages",
    CHANNELS: "channels:{serverId}:{channelId}",
    PRIVATE_ADD_MEMBER: "private:add:{userId}:member",
    PRIVATE_MEMBER: "private:{conversationId}:member",
    UPDATE_MEMBER: "update:{serverId}:member",
    UPDATE_PINNED: "update:{conversationId}:update:pinned",
    UPDATE_MEMBER_STATUS: "update:{serverId}:member:status",
    UPDATE_USER_STATUS: "update:user:status",
    LOGOUT_USER: "logout:{deviceId}:account",
    ACCOUNT_DELETE: "delete:{userId}:account",
    CONVERSATION_INACTIVATED: "conversation:{conversationId}:{deviceId}:inactivated",
    CONVERSATION_INACTIVE: "inactive:conversation",
    CONVERSATION_ACTIVATE: "active:conversation",
    USER_EVENT: "user:event:{userId}",
    NOTIFICATIONS: "notifications:{userId}",
};
const getSocketKey = (key, params) => {
    const template = exports.SOCKET_KEYS[key];
    return (0, helpers_1.replaceDynamicParams)(template, params);
};
exports.getSocketKey = getSocketKey;
